import { Checkbox } from '@mui/material';
import {
  createOrganisation,
  editOrganisation,
  getAllOrgUser,
  getOrgUsers,
} from 'actions/adminActions/organisationsActions';
import { updateUserOrganisationPassword } from 'actions/adminActions/usersActions';
import { hideAlert, showAlert } from 'actions/templateAction';
import SimpleHeader from 'components/Headers/SimpleHeader.jsx';
import { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Select from 'react-select';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { loaderType } from 'type';

const statusList = [
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
];

const AddOrgUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    visible: alertVisible,
    type,
    msg,
  } = useSelector((state) => state.template);

  const [state, setState] = useState({
    name: '',
    nameErr: null,
    email: '',
    emailErr: null,
    phoneNumber: '',
    phoneNumberErr: null,
    password: '',
    passwordErr: null,
    sendEmail: true,
    userType: 'organisation',
    tabAccess: [],
    status: statusList[0].value,
  });

  const users = useSelector((state) => state.organisation.orgData);

  useEffect(() => {
    dispatch(getOrgUsers(id, 0, 1000));
    trackPromise(dispatch(getAllOrgUser(0, 2000)), loaderType.GENERAL_REQUEST);
  }, [dispatch, id]);

  useEffect(() => {
    let findData = users.find((item) => item._id === id);
    setState({
      name: findData?.name || '',
      email: findData?.email || '',
      phoneNumber: findData?.phoneNumber || '',
      tabAccess: findData?.tabAccess || [],
      status: findData?.status ?? statusList[0].value,
    });
  }, [users, id]);

  const validateCustomStylesForm = async () => {
    let newState = { ...state };
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let phoneNumberRegex = /^[0-9]{10}$/;

    if (newState.name === '') {
      newState.nameErr = 'invalid';
    } else {
      newState.nameErr = 'valid';
    }

    if (newState.email === '' || !emailRegex.test(newState.email)) {
      newState.emailErr = 'invalid';
    } else {
      newState.emailErr = 'valid';
      newState.email = newState.email.trim();
    }

    if (
      newState.phoneNumber &&
      (newState.phoneNumber === '' ||
        !phoneNumberRegex.test(newState.phoneNumber))
    ) {
      newState.phoneNumberErr = 'invalid';
    } else {
      newState.phoneNumberErr = 'valid';
    }

    if (!id && newState.password === '') {
      newState.passwordErr = 'invalid';
    } else {
      newState.passwordErr = 'valid';
    }

    setState(newState);

    if (
      newState.nameErr === 'valid' &&
      newState.emailErr === 'valid' &&
      newState.passwordErr === 'valid' &&
      (newState.phoneNumber === '' || newState.phoneNumberErr === 'valid')
    ) {
      if (!id) {
        trackPromise(
          dispatch(createOrganisation({ ...state, userType: 'organisation' })),
          loaderType.GENERAL_REQUEST
        )
          .then((res) => {
            dispatch(showAlert(true, 'success', res.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err.message));
          });
      } else {
        updatePassword();
        trackPromise(
          dispatch(editOrganisation(id, state)),
          loaderType.GENERAL_REQUEST
        )
          .then((res) => {
            dispatch(showAlert(true, 'success', res?.data?.message));
          })
          .catch((err) => {
            dispatch(showAlert(true, 'warning', err.message));
          });
      }
    }
  };

  const updatePassword = () => {
    if (state.password) {
      trackPromise(
        dispatch(updateUserOrganisationPassword(id, state.password)),
        loaderType.GENERAL_REQUEST
      )
        .then((res) => {
          dispatch(showAlert(true, 'success', res.message));
        })
        .catch((err) => {
          dispatch(showAlert(true, 'warning', err.message));
        });
    }
  };

  const customStylesForm = (e) => {
    const { name, value } = e.target;
    let newState = { ...state, [name]: value };

    if (name === 'phoneNumber') {
      newState[name + 'Err'] =
        value === '' || /^[0-9]{10}$/.test(value) ? 'valid' : 'invalid';
    } else if (name === 'email') {
      newState[name + 'Err'] = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        ? 'valid'
        : 'invalid';
    } else {
      newState[name + 'Err'] = value === '' ? 'invalid' : 'valid';
    }

    setState(newState);
  };

  const navigateTo = () => {
    if (type === 'success') {
      history.push('/organisations');
    }
  };

  const handleConfirm = () => {
    dispatch(hideAlert());
    navigateTo();
  };

  const handleChecked = (e) => {
    const { name, checked } = e.target;
    setState((prevState) => {
      const updatedAccess = checked
        ? [...prevState.tabAccess, name]
        : prevState.tabAccess.filter((access) => access !== name);

      return {
        ...prevState,
        tabAccess: updatedAccess,
      };
    });
  };

  return (
    <>
      <SimpleHeader name="Add User" parentName="Users" />
      {alertVisible && (
        <SweetAlert
          success={type === 'success'}
          warning={type === 'warning'}
          title={msg}
          onConfirm={handleConfirm}
        />
      )}
      <Container fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="add_course_page_header">
                    <div className="d-flex gap-3 align-items-center">
                      <h5 className="org_page_title">
                        {!id ? 'Add' : 'Edit'} Organisation
                      </h5>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <button
                        className="cancel_form_btn"
                        onClick={() => history.push('/organisations')}
                      >
                        Cancel
                      </button>
                      <button
                        className="publish_form_btn"
                        onClick={validateCustomStylesForm}
                      >
                        {id ? 'Edit' : 'Add'} Organisation
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form noValidate autoComplete="off">
                    <div className="form-row">
                      <Col md="7">
                        <FormGroup>
                          <label className="org_form_label my-2 mx-0">
                            Name
                          </label>
                          <Input
                            value={state.name}
                            name="name"
                            placeholder="Name"
                            type="text"
                            autoComplete="off"
                            valid={state.nameErr === 'valid'}
                            invalid={state.nameErr === 'invalid'}
                            onChange={customStylesForm}
                            className="org_form_input"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="7">
                        <FormGroup>
                          <label className="org_form_label my-2 mx-0">
                            Email
                          </label>
                          <Input
                            value={state.email}
                            name="email"
                            placeholder="Email"
                            type="email"
                            autoComplete="off"
                            valid={state.emailErr === 'valid'}
                            invalid={state.emailErr === 'invalid'}
                            onChange={customStylesForm}
                            className="org_form_input"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="7">
                        <FormGroup>
                          <label className="org_form_label my-2 mx-0">
                            Phone Number
                          </label>
                          <Input
                            value={state.phoneNumber}
                            name="phoneNumber"
                            placeholder="Phone Number"
                            type="number"
                            autoComplete="off"
                            valid={
                              state.phoneNumber === '' ||
                              state.phoneNumberErr === 'valid'
                            }
                            invalid={
                              state.phoneNumber !== '' &&
                              state.phoneNumberErr === 'invalid'
                            }
                            onChange={customStylesForm}
                            className="org_form_input"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="7">
                        <FormGroup>
                          <label className="org_form_label my-2 mx-0">
                            Password
                          </label>
                          <Input
                            value={state.password}
                            name="password"
                            placeholder="Password"
                            type="password"
                            autoComplete="off"
                            valid={state.passwordErr === 'valid'}
                            invalid={state.passwordErr === 'invalid'}
                            onChange={customStylesForm}
                            className="org_form_input"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="7">
                        <label className="org_form_label my-2 mx-0">
                          Status
                        </label>
                        <Select
                          value={statusList.find(
                            (item) => item.value === state.status
                          )}
                          name="status"
                          options={statusList}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              minHeight: '45px',
                              border: state.statusErr
                                ? '1px solid red'
                                : '1px solid #d2d6da',
                            }),
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            setState({
                              ...state,
                              status: e.value,
                            });
                          }}
                        />
                      </Col>
                      <Col className="mt-4 mb-2" md="7">
                        <label className="org_form_label my-2 mx-0">
                          Feature Access
                        </label>
                      </Col>
                      <Col className="mb-2" md="7">
                        <table className="tab-access-tbl">
                          <thead>
                            <tr>
                              <th>Feature</th>
                              <th>Access</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Quiz</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isQuiz')}
                                  name="isQuiz"
                                  id="isQuiz"
                                  onChange={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Teams</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isTeams')}
                                  name="isTeams"
                                  id="isTeams"
                                  onChange={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Exams</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isExams')}
                                  name="isExams"
                                  id="isExams"
                                  onChange={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>CTF</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isCTF')}
                                  name="isCTF"
                                  id="isCTF"
                                  onChange={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>My Courses</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes(
                                    'isMycourses'
                                  )}
                                  name="isMycourses"
                                  id="isMycourses"
                                  onChange={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Licenses</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes(
                                    'isLicenses'
                                  )}
                                  name="isLicenses"
                                  id="isLicenses"
                                  onChange={handleChecked}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>Announcement</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes(
                                    'isAnnouncemnet'
                                  )}
                                  name="isAnnouncemnet"
                                  id="isAnnouncemnet"
                                  onChange={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Users</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isUser')}
                                  name="isUser"
                                  id="isUser"
                                  onChange={handleChecked}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Guests</td>
                              <td>
                                <Checkbox
                                  checked={state.tabAccess.includes('isGuest')}
                                  name="isGuest"
                                  id="isGuest"
                                  onChange={handleChecked}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AddOrgUser;
